import "./Pictures.css";
// import pic1 from '../../images/pr6.jpeg'
import pic2 from '../../images/fasad.jpg'
// import pic3 from '../../images/prechistenko8.jpg'
// import pic4 from '../../images/prechistenko4.jpg'
import dm3 from "../../images/dm3.jpg";
import dm4 from "../../images/dm4.jpg";
import lest from "../../images/lest.jpg";
export default function Pictures() {
  return (
    <section className="pictures page__size">
      <div className="pictures__container">
        <figure className="pictures__card">
          <img
            src={dm4}
            alt="фасад"
            className="pictures__img image__size"
          />
        </figure>
        <figure className="pictures__card">
          <img
            src={pic2}
            alt="Фасад 1 (Историческая часть )."
            className="pictures__img image__size"
          />
          <figcaption className="pictures__sign">Фасад 1 (Историческая часть )</figcaption>
        </figure>
        <figure className="pictures__card">
          <img
            src={dm3}
            alt="Фасад 2 ( Неисторическая часть )"
            className="pictures__img image__size"
          />
                    <figcaption className="pictures__sign">Фасад 2 ( Неисторическая часть )</figcaption>
        </figure>
        <figure className="pictures__card">
          <img
            src={lest}
            alt="лестничный проём"
            className="pictures__img image__size"
          />
        </figure>
      </div>
    </section>
  );
}
