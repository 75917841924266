import "./Contacts.css";
import Margarita from "../../images/saller2.JPG";
import { Link } from "react-router-dom";

export default function Contacts() {
  const phoneNumber = "+79189187545";
  const email = "r.riviera-1@mail.ru";
  return (
    <section className="contacts page__size" id="contact">
      <div className="contacts__container">
        <img
          src={Margarita}
          alt="Маргарита Афанасьева,
Руководитель департамента
сопровождения сделок с недвижимостью"
          title="Маргарита Афанасьева,
Руководитель департамента
сопровождения сделок с недвижимостью"
          className="contacts__img image__size"
        />
        <div className="contacts__block">
          <div className="contacts__block-text">
            <h2 className="contacts__title">Маргарита Афанасьева</h2>
            {/* <div className="contacts__subtitle">
              Руководитель департамента продаж
            </div> */}
          </div>
          <div className="contacts__block-tel">
            <Link to={`tel:${phoneNumber}`} className="contact__links">
              <span>тел:</span> <strong>{phoneNumber}</strong>
            </Link>
            <Link to={`mailto:${email}`} className="contact__links">
              <span>e-mail:</span> <strong>{email}</strong>
            </Link>
          </div>
          <ul className="contacts__block-links">
            <li className="contacts__link">Адрес:</li>
            <li className="contacts__link">119 034 г. Москва</li>
            <li className="contacts__link">ул. Пречистенка 33/19с2</li>
          </ul>
          <div className="contacts__map">
          <iframe className="contacts__map-item" src="https://yandex.ru/map-widget/v1/?um=constructor%3Ad92fd158e5f6724d3e04f673d7d061f0ba5096b73622719b1c9fb6eb6621638d&amp;source=constructor" title="Карта Яндекс"></iframe>          </div>
        </div>
      </div>
    </section>
  );
}
