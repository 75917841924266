import plate from '../../images/plate1.jpg'
import plate1 from '../../images/pl.png'
import plan3 from '../../images/plan3.png'
import plan10 from '../../images/plan10.png'
import plate5 from '../../images/plate6.jpg'
import plate6 from '../../images/plate5.jpg'
import plan4 from '../../images/plankv4.png'
import plate7 from '../../images/platekv4_1.jpg'
import plate8 from '../../images/platekv4.jpg'
import plate9 from '../../images/platekv7_1.jpg'
import plate10 from '../../images/platekv7.jpg'
import plan7 from '../../images/plankv7.jpg'
import plan8 from '../../images/plankv8.jpg'
import plate11 from '../../images/plate2.jpg'
import plate12 from '../../images/plate3.jpg'
import kv1 from '../../images/kv1i1.jpg'
import kv12 from '../../images/kv1i2.jpg'
import kv2 from '../../images/kv2i1.jpg'
import kv22 from '../../images/kv2i2.jpg'
import kv5 from '../../images/kv5i1.jpg'
import kv52 from '../../images/kv5i2.jpg'
import kv6 from '../../images/kv6i1.jpg'
import kv62 from '../../images/kv6i2.jpg'
import plan1 from '../../images/plankv1.png'
import plan2 from '../../images/plankv2.png'
import plan5 from '../../images/plankv5.png'
import plan6 from '../../images/plankv6.png'
import plan9 from '../../images/plankv9.jpg'
import kv9 from '../../images/kv9i1.jpg'
import kv92 from '../../images/kv9i2.jpg'
const apartments = [
  {
    title: "Квартира №1",
    img: plan1,
    subtitle: '«квартира»',
    details: [
      { title: "Этаж", value: "1/2" },
      { title: "Площадь", value: "113 м2" },
      { title: "Высота потолка", value: "до 3,4 м" },
      { title: "Выход", value: "в «секретный дворик»" }
    ],
    images: [
      { img: kv1, alt: "изображение квартира №1" },
      { img: kv12, alt: "изображение квартира №1" }
    ]
  },
  {
    title: "Квартира №2",
    img: plan2,
    subtitle: '«квартира»',
    details: [
      { title: "Этаж", value: "1/2" },
      { title: "Площадь", value: "74,4 м2" },
      { title: "Высота потолка", value: "до 3,4 м" },
      { title: "Выход", value: "в «секретный дворик»" }
    ],
    images: [
      { img: kv2, alt: "изображение квартира №2" },
      { img: kv22, alt: "изображение квартира №2" }
    ]
  },
    {
      title: "Квартира №3",
      img: plan3,
      subtitle: '«квартира»',
      details: [
        { title: "Этаж", value: "2/2" },
        { title: "Площадь", value: "114,5 м2" },
        { title: "Высота потолка", value: "до 4,5 м" },
        { title: "Выход", value: "в «секретный дворик»" }
      ],
      images: [
        { img: plate, alt: "изображение квартира №3" },
        { img: plate1, alt: "изображение квартира №3" }
      ]
    },
    {
      title: 'Квартира №4',
      img: plan4,
      subtitle: '«апартаменты»',
      details: [
        { title: "Этаж", value: "2/2" },
        { title: "Площадь", value: "75,7 м2" },
        { title: "Высота потолка", value: "до 4,5 м" },
        { title: "Выход", value: "в «секретный дворик»" },
      ],
      images: [
        { img: plate7, alt: "изображение квартира №4" },
        { img: plate8, alt: "изображение квартира №4" }
      ]
    },
    {
      title: "Квартира №5",
      img: plan5,
      subtitle: '«апартаменты»',
      details: [
        { title: "Этаж", value: "1/2" },
        { title: "Площадь", value: "107 м2" },
        { title: "Высота потолка", value: "до 3,4 м" },
        { title: "Выход", value: "в «секретный дворик»" }
      ],
      images: [
        { img: kv5, alt: "изображение квартира №5" },
        { img: kv52, alt: "изображение квартира №5" }
      ]
    },
    {
      title: "Квартира №6",
      img: plan6,
      subtitle: '«апартаменты»',
      details: [
        { title: "Этаж", value: "2/2" },
        { title: "Площадь", value: "109,6 м2" },
        { title: "Высота потолка", value: "до 4,5 м" },
        { title: "Выход", value: "в «секретный дворик»" }
      ],
      images: [
        { img: kv6, alt: "изображение квартира №6" },
        { img: kv62, alt: "изображение квартира №6" }
      ]
    },
    {
      title: 'Квартира №7',
      img: plan7,
      subtitle: '«квартира»',
      details: [
        { title: "Этаж", value: "2/2" },
        { title: "Площадь", value: "92,1 м2" },
        { title: "Высота потолка", value: "3,3 м" }
      ],
      images: [
        { img: plate9, alt: "изображение квартира №7" },
        { img: plate10, alt: "изображение квартира №7" }
      ]
    },
    {
      title: 'Квартира №8',
      img: plan8,
      subtitle: '«квартира»',
  
      details: [
        { title: "Этаж", value: "1/2" },
        { title: "Площадь", value: "122,1 м2" },
        { title: "индивидуальный вход",  },
        { title: "исторические сводчатые потолки",  }
      ],
      images: [
        { img: plate11, alt: "изображение квартира №8" },
        { img: plate12, alt: "изображение квартира №8" }
        
      ]
    },
    {
        title: 'Квартира №9',
        img: plan9,
        subtitle: '«квартира»',
        details: [
          { title: "Этаж", value: "2/2" },
          { title: "Площадь", value: "106,6 м2" },
          { title: "Высота потолка", value: "5 м" }
        ],
        images: [
          { img: kv92, alt: "изображение квартира №9" },
          { img:kv9, alt: "изображение квартира №9" }
          
        ]
      },
    
  
  ];
  
  const loftApartment = [  {
  

    
    title: "Квартира №10",
    img: plan10,
    subtitle: '*антресоль',
    text: '*чердак',
    details: [
      { title: "Этаж", value: "2/2" },
      { title: "Площадь", value: "157,8 м2+ 78,6 м2 (чердак)" },
      { title: "Высота потолка", value: "от 3,3 м" }
    ],
    images: [
      { img: plate5, alt: "Plate Image 1" },
      { img: plate6, alt: "Plate Image 2" }
    ]
  },
];
  
  export { apartments, loftApartment };

